@import "./css/vendor/react-phone-number-input.scss";
@import "./css/vendor/react-js-pagination.scss";
@import "./css/toast.scss";

.App {
  display: flex;
  flex-direction: column;
}

body,
html,
#root,
.App {
  height: 100%;
  overflow-x: hidden !important;
}

::-webkit-scrollbar {
  display: none;
}
