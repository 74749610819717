body {
  /* background-color: #000 !important; */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ReactModal__Overlay {
  z-index: 100 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.homeCard {
  border-radius: 0.5em;
  height: 75vmin;
  max-height: 500px;
  pointer-events: none;
}

.homeCardContainer {
  animation: floating 6s ease-in-out infinite;
}

.preview {
  animation: rotating 4s linear alternate infinite;
}

@keyframes floating {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes rotating {
  0% {
    transform: rotateY(0deg);
  }
  33% {
    transform: rotateY(15deg);
  }
  66% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(-15deg);
  }
}

.wrapper {
  position: relative;
  height: 100vh;
}

.App-header {
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 0;
}

.App-link {
  color: #61dafb;
}

.ufo {
  animation: ufo 1.5s linear alternate infinite;
}

@keyframes ufo {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-3px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes move-twink-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}
@-webkit-keyframes move-twink-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}
@-moz-keyframes move-twink-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}
@-ms-keyframes move-twink-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}

@keyframes move-clouds-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 10000px 0;
  }
}
@-webkit-keyframes move-clouds-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 10000px 0;
  }
}
@-moz-keyframes move-clouds-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 10000px 0;
  }
}
@-ms-keyframes move-clouds-back {
  from {
    background-position: 0;
  }
  to {
    background-position: 10000px 0;
  }
}

.stars,
.twinkling,
.clouds {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.clouds {
  opacity: 0.4;
  filter: contrast(0.1);
}

.stars {
  background: #000
    url(http://www.script-tutorials.com/demos/360/images/stars.png) repeat top
    center;
  z-index: 0;
}

.twinkling {
  background: transparent
    url(http://www.script-tutorials.com/demos/360/images/twinkling.png) repeat
    top center;
  z-index: 1;

  -moz-animation: move-twink-back 200s linear infinite;
  -ms-animation: move-twink-back 200s linear infinite;
  -o-animation: move-twink-back 200s linear infinite;
  -webkit-animation: move-twink-back 200s linear infinite;
  animation: move-twink-back 200s linear infinite;
}

.clouds {
  background: transparent url(./assets/clouds.png) repeat top center;
  z-index: 2;

  -moz-animation: move-clouds-back 200s linear infinite;
  -ms-animation: move-clouds-back 200s linear infinite;
  -o-animation: move-clouds-back 200s linear infinite;
  -webkit-animation: move-clouds-back 200s linear infinite;
  animation: move-clouds-back 200s linear infinite;
}

.card {
  position: relative;
  cursor: pointer;
}

.card::before,
.card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card::before,
.card::after,
.card .card-inner {
  background-color: var(--card-bg-color);
  border: 1px solid var(--card-border-color);
  border-radius: 0.5em;
  transition: transform 0.3s;
}

.card::before,
.card-inner {
  z-index: 1;
}

.card-inner {
  position: relative;
}

/*TOP LEFT*/
.card-top-left::before {
  transform: translate(
    calc(-1 * var(--offset-before)),
    calc(-1 * var(--offset-before))
  );
}

.card-top-left::after {
  transform: translate(
    calc(-1 * var(--offset-after)),
    calc(-1 * var(--offset-after))
  );
}

.card-top-left:hover::before,
.card-top-left:hover::after,
.card-top-left:hover .card-inner {
  transform: translate(calc(-1 * var(--offset-before)), 0);
}

:root {
  --card-border-color: #e2d9d5;
  /* --card-bg-color: #fff; */
  --offset-before: 8px;
  --offset-after: 16px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.mintCard {
  border-radius: 0.5em;
}

.slick-dots {
  bottom: -25px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
  color: white;
}

.slick-slider {
  width: inherit;
}

.custom-dots {
  color: white;
}

.custom-dots li button:before {
  color: #898989;
}

.custom-dots li.slick-active button:before {
  color: white;
}

.countdown-gradient {
  background: -webkit-linear-gradient(60deg, #ff0c0c 0%, #ff8e01 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
